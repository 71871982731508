<template>
  <div class="w-100 h-100">
    <CompactLayout v-if="layout == 'Compact'" />
    <template v-else>
      <div class="profile-container shadow-sm">
        <div class="content-profile">
          <b-row class="sub-content" no-gutters>
            <b-col cols="3" class="d-flex">
              <label class="panel-image my-auto">
                <div class="profile-img"></div>
              </label>

              <div class="text-center qr-custom"></div>
            </b-col>

            <b-col cols="8">
              <div class="width-sub-content">
                <div class="f-12">Member Level</div>
                <div>
                  <span class="f-14 text-bold">Firstname Lastname </span>
                </div>
                <div class="f-12">(Member ID.)</div>
              </div>
            </b-col>
            <b-col class="text-right pr-0" cols="1">
              <b-dropdown
                no-caret
                right
                variant="custom-link"
                toggle-class="text-decoration-none border-none"
                class="text"
                menu-class="shadow border-none chart-rounded"
              >
                <template #button-content>
                  <font-awesome-icon
                    :icon="['fas', 'ellipsis-v']"
                    class="icon-edit-profile"
                  />
                </template>
                <b-dropdown-item link-class="px-3">
                  <div class="content-between">
                    <span>แก้ไข</span>
                  </div>
                </b-dropdown-item>
                <template v-if="displayLogout == 1">
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item link-class="px-3">
                    <div class="content-between">
                      <span>ออกจากระบบ</span>
                    </div>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </b-col>
          </b-row>
          <div class="pt-3 px-1">
            <div
              class="content-menu-list px-3 py-1"
              v-if="
                (displayPoint == 1 &&
                  (layout == 'Tier' || layout == 'Tier (Full Display)')) ||
                layout == 'Standard'
              "
            >
              <template v-if="layout == 'Standard'">
                <b-row>
                  <template v-if="displayPoint == 1">
                    <b-col
                      :cols="displayConnect == 1 ? 6 : 12"
                      :class="displayConnect == 1 ? 'point-panel' : ''"
                    >
                      <div class="f-10 content-between">
                        คะแนนคงเหลือ / ระดับสมาชิก:
                        <font-awesome-icon
                          :icon="['fas', 'chevron-right']"
                          class="highlight-color cursor-pointer"
                        />
                      </div>
                      <div class="f-12 highlight-color">
                        <font-awesome-icon
                          :icon="['fas', 'crown']"
                          class="mr-1"
                        />
                        <span class="ft-weight">{{
                          7000 | numeral("0,0")
                        }}</span>
                        คะแนน
                        <span
                          class="cursor-pointer"
                          v-if="displayMemberLevel == 1"
                          >(Tier)</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="6" v-if="displayConnect == '1'">
                      <div class="content-between">
                        <div class="f-10">
                          ช่องทางสะสมคะแนน:
                          <u
                            class="highlight-color f-10"
                            v-if="displayConnect == '1'"
                          >
                            <font-awesome-icon :icon="['fas', 'plus']" />เพิ่ม
                          </u>
                        </div>
                        <font-awesome-icon
                          :icon="['fas', 'chevron-right']"
                          class="highlight-color cursor-pointer f-10"
                        />
                      </div>
                      <!-- <div class="marketplace-panel">
                <template
                  v-for="(market_list, index) of marketPlaceObj.marketplace"
                >
                  <div
                    class="icon-marketplace"
                    v-if="index < 4"
                    :key="
                      market_list.shopName +
                      market_list.branchId +
                      '' +
                      market_list.id
                    "
                  >
                    <img
                      :src="
                        market_list.marketplaceUrl ||
                        'https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/231026021009526.jpg'
                      "
                      alt="Marketplace icon"
                      :class="[market_list.status ? '' : 'op-low']"
                    />
                  </div>
                </template>
              </div> -->
                    </b-col>
                  </template>
                </b-row>
              </template>
              <template
                v-else-if="layout == 'Tier' || layout == 'Tier (Full Display)'"
              >
                <b-row>
                  <b-col cols="6">
                    <div class="d-inline-flex w-100 align-items-center">
                      <div class="member-tier-icon">
                        <font-awesome-icon :icon="['fas', 'crown']" />
                      </div>
                      <div>
                        <span class="f-12">ระดับสมาชิก</span>

                        <font-awesome-icon
                          :icon="['fas', 'chevron-right']"
                          class="highlight-color cursor-pointer f-12 ml-2"
                        />
                        <div class="cursor-pointer text-bold">Tier</div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <template v-if="displayPoint == 1">
                      <div
                        class="d-inline-flex w-100 align-items-center justify-content-end text-nowrap"
                      >
                        <div class="f-12">
                          คะแนนสะสมปัจจุบัน:
                          <u
                            class="ml-2 highlight-color f-10"
                            v-if="
                              layout != 'Tier' ||
                              layout != 'Tier (Full Display)'
                            "
                          >
                            <font-awesome-icon
                              :icon="['fas', 'plus']"
                              class="mr-1"
                            />เพิ่ม</u
                          >
                        </div>
                      </div>
                      <div class="ft-weight highlight-color text-right">
                        <font-awesome-icon
                          :icon="['fas', 'gift']"
                          class="f-12 mr-2"
                        />{{ 800 | numeral("0,0") }}
                      </div>
                    </template>
                  </b-col>
                </b-row>
                <template>
                  <template>
                    <div class="reletive">
                      <b-progress
                        class="mt-3 mb-1"
                        height="10px"
                        :value="80"
                        :max="100"
                      >
                      </b-progress>
                    </div>
                    <div class="d-inline-flex w-100 f-12">
                      <div>
                        {{
                          layout == "Tier (Full Display)"
                            ? "ยอดสะสม"
                            : "สะสมยอดเพิ่มเพื่อรับสถานะ"
                        }}
                      </div>
                      <div class="ml-auto">
                        <span class="highlight-color text-bold"
                          >{{ 10000 | numeral("0,0") }}/{{
                            20000 | numeral("0,0")
                          }}</span
                        >
                        บาท
                      </div>
                    </div>
                  </template>
                  <div class="d-inline-flex w-100 f-12">
                    <template>
                      <div>ระดับสมาชิกจะหมดอายุภายใน</div>
                      <div class="ml-auto text-bold text-error">
                        {{ $moment().add(543, "y").format("DD MMM YYYY") }}
                        <font-awesome-icon
                          :icon="['fas', 'info-circle']"
                          class="f-12 ml-2"
                          id="tooltip-info"
                        />
                        <b-tooltip
                          target="tooltip-info"
                          triggers="click"
                          custom-class="tooltip-info"
                        >
                          <div class="content-between">
                            <div>ระดับสมาชิกจะหมดอายุภายใน</div>
                            <div class="text-error text-bold">
                              {{
                                $moment().add(543, "y").format("DD MMM YYYY")
                              }}
                            </div>
                          </div>
                          <div class="content-between">
                            <div>สะสมยอดเพิ่มเพื่อรักษาระดับคะแนน</div>
                            <div class="text-error text-bold">
                              {{ 8000 | numeral("0,0") }}
                              บาท
                            </div>
                          </div>
                        </b-tooltip>
                      </div>
                    </template>
                  </div>
                </template>
                <!-- <div v-else class="h-50x"></div> -->
              </template>
            </div>
            <div v-else-if="displayMemberLevel == 1">
              <b-card class="bg-gray">
                <div class="w-100 card-display d-flex align-items-center">
                  <div>
                    <h4 class="text-gray">Card Preview</h4>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="my-2 mx-3">
        <div class="menulist-container shadow-sm">
          <div class="bg-menulist">
            <div class="menu-list" v-for="list of MenuList" :key="list.id">
              <b-row>
                <b-col cols="3">
                  <span class="px-3">
                    <font-awesome-icon
                      :icon="['fas', list.icon]"
                      class="ft-20"
                    />
                  </span>
                </b-col>
                <b-col class="p-0" cols="7">
                  <span>{{ list.name }}</span>
                </b-col>
                <b-col cols="2">
                  <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="icon-color"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CompactLayout from "./CompactLayout.vue";
export default {
  props: {
    layout: { required: true },
    displayMemberLevel: { required: true },
    displayLogout: { required: true },
    displayPoint: { required: true },
    displayConnect: { required: true },
  },
  components: {
    CompactLayout,
  },
  data() {
    return {
      MenuList: [
        {
          id: 1,
          name: "สิทธิพิเศษ",
          icon: "ticket-alt",
          path: "privilege",
          bg_color: "",
        },
        {
          id: 2,
          name: "ของขวัญ",
          icon: "gift",
          path: "reward",
          bg_color: "",
        },
        {
          id: 3,
          name: "ข่าวสาร",
          icon: "newspaper",
          path: "news",
          bg_color: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$selectedColor: var(--text-color);
$blue: $selectedColor;
.icon-marketplace {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }
  .cover {
    background: #858585;
    opacity: 0.5;
    position: absolute;
    top: 0;
    height: 30px;
    width: 30px;
    left: 0;
    border-radius: 50%;
  }
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* overflow: hidden; */
  position: relative;
  padding: 1px;
  margin-top: 2px;
}
.content-profile {
  width: 100%;
  padding: 10px;
  // background-color: white;
  border-radius: 10px;
}
.name {
  font-size: 16px;
  font-weight: 600;
}
.sub-content {
  padding: 0 10px 10px 10px;
  position: relative;
}
.icon-edit-profile {
  font-size: 20px;
  color: #858585;
}
.profile-ft-color {
  color: #858585;
}
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  background-image: url(/img/avatars/9.png);
}
.panel-image {
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: 3px solid #000;
  border-radius: 50%;
  bottom: 0px;
  display: flex;
  margin: auto 0;
}
.box-point {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}
.position-img {
  position: absolute;
  right: 120px;
  top: 110px;
}
.pd-box-point {
  padding: 45px 0;
}
.qr-custom {
  position: absolute;
  top: 70px;
  margin-left: 50px;
}
.qr-size {
  width: 65%;
}

.ft-14 {
  font-size: 14px;
}
.ft-13 {
  font-size: 13px;
}
.ft-10 {
  font-size: 10px;
}
.icon-color {
  color: #c9c9c9;
}
.icon-color-gray {
  color: #5d5d5d;
}
.ft-15 {
  font-size: 15px;
}
.ft-weight {
  font-weight: bold;
}
.width-sub-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 190px;
}
.icon-level {
  width: 25%;
}
@media only screen and (orientation: portrait) and (min-width: 737px) and (max-width: 1023px) {
  /* For iPad mini , iPad */
  .qr-size {
    width: 30%;
  }
  .qr-custom[data-v-755e242a] {
    margin-left: 30px;
  }
  .icon-level {
    width: 12%;
  }
}
@media only screen and (orientation: portrait) and (min-width: 1024px) and (max-width: 1366px) {
  /* For iPad Pro */
  .qr-size {
    width: 30%;
  }
  .qr-custom[data-v-755e242a] {
    margin-left: 30px;
  }
  .icon-level {
    width: 10%;
  }
}
.point-panel {
  border-right: 1px solid #c9c9c9;
  line-height: 2;
}
.marketplace-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
}
.op-low {
  opacity: 0.15;
}
.progress {
  height: 10px;
  position: relative;
  border: 1px solid #c9c9c9;
  background-color: white;
  overflow: visible;
}

@mixin background-opacity($color) {
  background-image: linear-gradient(to right, $color);
  background-image: linear-gradient(to right, $color, rgba($blue, 0.5));
}

::v-deep .progress-bar {
  border-radius: 0.25rem;
  background: $selectedColor;
  // @include background-opacity($selectedColor);
}
.range-avg {
  height: 10px;
  width: 2px;

  background: $selectedColor;
  z-index: 1;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
.h-50x {
  height: 50px;
}
::v-deep.tooltip-info .tooltip-inner {
  background: white;
  color: #7a7a7a;
  width: 250px;
  max-width: unset;
  font-size: 10px;
}
::v-deep.tooltip-info {
  opacity: 1;
}
::v-deep.bs-tooltip-top .arrow::before {
  border-top-color: white;
}
.profile-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
}
.member-tier-icon {
  background: rgb(206, 206, 206);
  margin-right: 0.5rem;
  border-radius: 50%;
  padding: 2px;
  width: 34px;
  height: 32px;
  text-align: center;
}
.member-tier-icon > svg {
  color: white;
  font-size: 14px;
}
.content-menu-list {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.bg-menulist {
  // background-color: #f1f1f1;
  .menu-list {
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.bg-menulist .menu-list:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}
.menu-list {
  background-color: white;
  color: black;
  padding: 20px 0;
}
.menu-list-bottom {
  padding: 20px 0;
}
.ft-20 {
  font-size: 20px;
}
.icon-color {
  color: #c9c9c9;
}
.bg-gray {
  background-color: whitesmoke;
  border-color: whitesmoke;
  border-radius: 15px;
}
.card-display {
  height: 135px;
}
</style>
